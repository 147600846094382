const setupReportTemplateFieldTag = (win, doc, editor) => {
  const template = doc.createElement("template");
  template.innerHTML = `
    <style>
      :host {}
      :host(:hover) {
        background-color: rgba(0, 108, 231, 0.1);
      }
      :host([contentEditable="false"][data-mce-selected]) {
        cursor: not-allowed;
        outline: 3px solid #b4d7ff;
      }
      
      .report-template-field-affix {
        background-color: rgba(0, 108, 231, 0.1);
        color: #006ce7;
      }
    </style>

    <span>
      <slot>field-not-defined</slot>
    </span>
  `;

  class ReportTemplateFieldTag extends win.HTMLElement {
    constructor() {
      super();

      const shadowRoot = this.attachShadow({ mode: "open" });

      let prefixElement = document.createElement("span");
      prefixElement.classList.add("report-template-field-affix");
      prefixElement.innerHTML = "{*";

      this.shadowRoot.appendChild(prefixElement);

      this.shadowRoot.appendChild(template.content.cloneNode(true));

      let affixElement = document.createElement("span");
      affixElement.classList.add("report-template-field-affix");
      affixElement.innerHTML = "*}";

      this.shadowRoot.appendChild(affixElement);
    }

    connectedCallback() {
      this.setAttribute("contentEditable", false);

      if (editor) {
        this.addEventListener("dblclick", (e) => {
          let allowEdit = false;

          if (this.getAttribute("data-field-predefined")) {
            if (
              this.getAttribute("data-field-variant") ||
              this.getAttribute("data-field-case-type")
            ) {
              allowEdit = true;
            }
          } else {
            allowEdit = true;
          }

          if (allowEdit) {
            const event = new CustomEvent("field-dialog:open", {
              detail: {
                fieldId: this.getAttribute("data-id"),
                displayOptions: this.attributes,
              },
            });

            editor.getDoc().dispatchEvent(event);
          }
        });
      }
    }

    static get observedAttributes() {
      return [
        "data-id",
        "data-field-name",
        "data-field-predefined",
        "data-field-type",
        "data-field-case-type",
        "data-field-variant",
        "data-field-list-type",
        "data-field-default-text",
      ];
    }
  }

  win.customElements.define("report-template-field", ReportTemplateFieldTag);
};

export { setupReportTemplateFieldTag };
