import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['totalPaid', 'adjustment']
  static values = {
    claimTotal: Number
  }

  connect() {
    this.claimTotal = Number(this.claimTotalValue)
  }

  calculate(e) {
    this.totalPaid = 0.00

    document.querySelectorAll('.payment-amount').forEach(element => {
      if (element.offsetParent !== null) {
        let value = Number(element.value)

        // Add the payment amount to the total paid
        this.totalPaid += value
      }
    })

    // Subtract the total paid from the claim total
    this.adjustment = this.claimTotal - this.totalPaid

    let formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    })

    this.totalPaidTarget.innerText = formatter.format(this.totalPaid)
    this.adjustmentTarget.innerText = formatter.format(this.adjustment)
  }
}