import WysiwygController from './wysiwyg_controller'

const styles = `
  body {
    padding: 1rem;
  }
`

// Connects to data-controller="small-editor"
export default class extends WysiwygController {

  connect() {
    super.connect()
  }

  get optionsValue() {
    return {
      menubar: false,
      height: '250px',
      content_css: false,
      content_style: styles 
    }
  }
}
