import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="deny-link-click"
export default class extends Controller {
  connect() {
    this.element.addEventListener("click", this.denyLinkClick);
  }

  denyLinkClick(event) {
    event.preventDefault();

    alert("Access Denied");
  }
}
