export default class DynamicContentDialog {
  editor;
  fields;
  dialog;
  selectedField;
  condition;
  value;

  constructor(editor, fields, dynamicContentNode = null) {
    this.editor = editor;
    this.fields = fields;
    this.selectedField = null;
    this.condition = null;
    this.value = null;
    this.dialog = null;
    this.innerHTML = null;

    if (dynamicContentNode) {
      let dynamicContentOptions = dynamicContentNode.attributes;

      if (dynamicContentOptions["data-field-id"]) {
        let fieldId = dynamicContentOptions["data-field-id"].value;
        this.selectedField =
          this.fields.find((field) => field.id === parseInt(fieldId)) || null;
      }

      if (dynamicContentOptions["data-condition"]) {
        this.condition = dynamicContentOptions["data-condition"].value;
      }

      if (dynamicContentOptions["data-value"]) {
        this.value = dynamicContentOptions["data-value"].value;
      }

      this.innerHTML = dynamicContentNode.innerHTML;
    }
  }

  show() {
    this.dialog = this.editor.windowManager.open(this.#getDialogConfig());
  }

  #getDialogConfig() {
    return {
      title: "Dynamic Content",
      body: {
        type: "panel",
        items: [
          {
            type: "bar",
            items: this.#generateConditionBarItems(),
          },
        ],
      },
      initialData: {
        selectedField: this.selectedField
          ? this.selectedField.id.toString()
          : "",
        condition: this.condition ? this.condition : "IS_BLANK",
        value: this.value ? this.value : "",
      },
      buttons: [
        {
          type: "cancel",
          name: "closebutton",
          text: "Cancel",
        },
        {
          type: "submit",
          name: "submitbutton",
          text: "OK",
          primary: true,
        },
      ],
      onChange: (api) => {
        let data = api.getData();

        if (data.selectedField) {
          this.selectedField =
            this.fields.find(
              (field) => field.id === parseInt(data.selectedField),
            ) || null;

          this.condition = data.condition;
          this.value = data.value;

          this.dialog.redial(this.#getDialogConfig());
        } else {
          this.selectedField = null;
          this.condition = "IS_BLANK";
          this.value = null;
        }
      },
      onSubmit: (api) => {
        let data = api.getData();

        let node = document.createElement("report-template-dynamic-content");
        node.setAttribute("data-field-id", data.selectedField);
        node.setAttribute("data-condition", data.condition);
        node.setAttribute(
          "data-condition-text",
          this.#generateConditionText(data),
        );

        if (data.value) {
          node.setAttribute("data-value", data.value);
        }

        if (this.innerHTML) {
          node.innerHTML = this.innerHTML;
        } else {
          node.innerText = "Dynamic Content";
        }

        this.editor.insertContent(node.outerHTML);
        api.close();
      },
    };
  }

  #generateConditionBarItems() {
    let fieldOptions = this.fields.map((field) => {
      return {
        text: field.name,
        value: field.id.toString(),
      };
    });

    fieldOptions.unshift({
      text: "Select a field",
      value: "",
    });

    // Create condtion options
    let conditionOptions = [
      {
        text: "Is Blank",
        value: "IS_BLANK",
      },
      {
        text: "Is Not Blank",
        value: "IS_NOT_BLANK",
      },
    ];

    // Add EQUALS AND NOT_EQUALS if the field type
    // is radio
    if (
      this.selectedField &&
      this.selectedField.values.length > 0 &&
      this.selectedField.field_type === "Radio"
    ) {
      conditionOptions.push({
        text: "Equals",
        value: "EQUALS",
      });

      conditionOptions.push({
        text: "Does not equal",
        value: "DOES_NOT_EQUAL",
      });
    }

    let boxItems = [
      {
        type: "selectbox",
        name: "selectedField",
        label: "Field",
        items: fieldOptions,
      },
      {
        type: "selectbox",
        name: "condition",
        label: "Condition",
        items: conditionOptions,
      },
    ];

    if (
      this.selectedField &&
      this.selectedField.values.length > 0 &&
      this.selectedField.field_type === "Radio" &&
      (this.condition === "EQUALS" || this.condition === "DOES_NOT_EQUAL")
    ) {
      boxItems.push({
        type: "selectbox",
        name: "value",
        label: "Value",
        items: this.selectedField.values.map((response) => {
          return {
            text: response.report_value,
            value: response.id.toString(),
          };
        }),
      });
    }

    return boxItems;
  }

  #generateConditionText(data) {
    let field =
      this.fields.find((field) => field.id === parseInt(data.selectedField)) ||
      null;

    let conditionText = `${field.name} ${data.condition}`;

    if (data.value) {
      let response =
        field.values.find((value) => value.id === parseInt(data.value)) || null;

      if (response) {
        conditionText = `${conditionText} ${response.user_value}`;
      }
    }

    return conditionText.toSentenceCase();
  }
}
