import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="intake-documents--form"
export default class extends Controller {
  static targets = [
    "incorrectFields",
    "panel",
    "nextButton",
    "submitButton",
    "navLink",
  ];

  activeLinkClasses = "nav-link active";
  inactiveLinkClasses = "nav-link";

  connect() {
    this.panelIndex = 0;
    this.showCurrentPanel();
  }

  showCurrentPanel() {
    this.panelTargets.forEach((panel, index) => {
      panel.hidden = index !== this.panelIndex;
    });

    this.addClassesToNavLinks();
    this.checkSubmitButton();
  }

  addIncorrectField(field, value) {
    let incorrectFields = this.incorrectFieldsTarget.value
      ? JSON.parse(this.incorrectFieldsTarget.value)
      : [];
    incorrectFields.push({ field: field, value: value });

    this.incorrectFieldsTarget.value = JSON.stringify(incorrectFields);
  }

  nextClicked(e) {
    e.preventDefault();

    if (this.panelIndex < this.panelTargets.length - 1) {
      this.panelIndex++;
      this.showCurrentPanel();
    }
  }

  showPanel(e) {
    e.preventDefault();

    this.panelIndex = parseInt(e.params.panel);
    this.showCurrentPanel();
  }

  addClassesToNavLinks() {
    this.navLinkTargets.forEach((link, index) => {
      if (index == this.panelIndex) {
        link.className = this.activeLinkClasses;
      } else {
        link.className = this.inactiveLinkClasses;
      }
    });
  }

  checkSubmitButton() {
    if (!this.hasNextButtonTarget) return;

    if (this.panelIndex == this.panelTargets.length - 1) {
      this.nextButtonTarget.classList.add("d-none");
      this.submitButtonTarget.classList.remove("d-none");
    } else {
      this.nextButtonTarget.classList.remove("d-none");
      this.submitButtonTarget.classList.add("d-none");
    }
  }
}
