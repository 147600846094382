import { setupReportTemplateFieldTag } from "../../../lib/report-template-field-tag";
import { setupReportTemplateDynamicContentTag } from "../../../lib/report-template-dynamic-content-tag";
import * as Buttons from "./ui/buttons";
import FieldDialog from "./ui/field_dialog";
import { makeRequest } from "./utils";
import DynamicContentDialog from "./ui/dynamic_content_dialog";

const setup = (editor) => {
  editor.options.register("reportTemplateFieldsOptions", {
    processor: "object",
  });

  let predefinedFieldsUrl = editor.options.get("reportTemplateFieldsOptions")[
    "predefinedFieldsUrl"
  ];
  let fieldsUrl = editor.options.get("reportTemplateFieldsOptions")[
    "fieldsUrl"
  ];
  let fieldUrl = editor.options.get("reportTemplateFieldsOptions")["fieldUrl"];

  let dynamicContentUrl = editor.options.get("reportTemplateFieldsOptions")[
    "dynamicContentUrl"
  ];
  let widgetType = editor.options.get("reportTemplateFieldsOptions")[
    "widgetType"
  ];

  Buttons.register(editor, {
    predefinedFieldsUrl: predefinedFieldsUrl,
    fieldsUrl: fieldsUrl,
    dynamicContentUrl: dynamicContentUrl,
    widgetType: widgetType,
  });

  editor.on("Init", () => {
    const win = editor.getWin();
    const doc = editor.getDoc();

    setupReportTemplateFieldTag(win, doc, editor);
    setupReportTemplateDynamicContentTag(win, doc, editor);

    editor.getDoc().addEventListener("field-dialog:open", async (e) => {
      // TODO: This is a hack, we should eventually fix
      if (e.detail.fieldId == "procedure_location") {
        const field = {
          id: "procedure_location",
          name: "Procedure Location",
          variants: [],
          enable_letter_case: true,
          predefined_field: true,
        };

        const dialog = new FieldDialog(editor, field, e.detail.displayOptions);
        dialog.show();

        return;
      }

      if (e.detail.fieldId == "procedure_type") {
        const field = {
          id: "procedure_type",
          name: "Procedure Type",
          variants: [],
          enable_letter_case: true,
          predefined_field: true,
        };

        const dialog = new FieldDialog(editor, field, e.detail.displayOptions);
        dialog.show();

        return;
      }

      let url = "";

      if (e.detail.displayOptions["data-field-predefined"]) {
        url = `${predefinedFieldsUrl}/${e.detail.fieldId}.json`;
      } else {
        url = `${fieldUrl.replace("PLACEHOLDER", e.detail.fieldId)}.json`;
      }

      const field = await makeRequest(url);
      const dialog = new FieldDialog(editor, field, e.detail.displayOptions);
      dialog.show();
    });

    editor
      .getDoc()
      .addEventListener("dynamic-content-dialog:open", async (e) => {
        const fields = await makeRequest(fieldsUrl);
        const dialog = new DynamicContentDialog(
          editor,
          fields,
          e.detail.dynamicContentNode,
        );

        dialog.show();
      });
  });

  editor.on("PreInit", () => {
    editor.serializer.addNodeFilter("report-template-field", (nodes) => {
      nodes.forEach((node) => {
        if (!!node.attr("contenteditable")) {
          node.attr("contenteditable", null);
        }
      });
    });
  });
};

export default () => {
  tinymce.PluginManager.add("report-template-fields", setup);
};
