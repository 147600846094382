import { Controller } from "@hotwired/stimulus";
import { get } from "@rails/request.js";

// Connects to data-controller="report-templates--references"
export default class extends Controller {
  static targets = [
    "reportTemplateSelect",
    "reportTemplateCategorySelect",
    "reportTemplateSectionSelect",
  ];

  static values = {
    url: String,
  };

  async connect() {
    // Check if report template select has a value
    if (this.reportTemplateSelectTarget.value) {
      await this.setupCategories(this.reportTemplateSelectTarget.value);
    }
  }

  async reportTemplateChanged(event) {
    if (!event.target.value) return;

    await this.setupCategories(event.target.value);
  }

  async setupCategories(reportTemplateId) {
    this.reportTemplateId = reportTemplateId;
    let url = this.urlValue.replace(":id", this.reportTemplateId);

    let shouldSetupSections = false;

    const response = await get(`${url}/categories`, {
      responseKind: "json",
    });

    if (response.ok) {
      const body = await response.json;
      const selectedId = this.reportTemplateCategorySelectTarget.dataset.id;

      this.reportTemplateCategorySelectTarget.innerHTML = "";

      let blankOption = document.createElement("option");
      blankOption.value = "";
      blankOption.innerText = "Select Category";
      this.reportTemplateCategorySelectTarget.appendChild(blankOption);

      for (const category of body) {
        let option = document.createElement("option");
        option.value = category.id;
        option.innerText = category.name;

        if (selectedId && selectedId == category.id.toString()) {
          option.selected = true;
          shouldSetupSections = true;
        }

        this.reportTemplateCategorySelectTarget.appendChild(option);
      }

      if (shouldSetupSections) {
        await this.setupSections(selectedId);
      }
    }
  }

  async reportTemplateCategoryChanged(event) {
    if (!event.target.value) return;

    await this.setupSections(event.target.value);
  }

  async setupSections(categoryId) {
    let url = this.urlValue.replace(":id", this.reportTemplateId);

    const response = await get(`${url}/sections?category_id=${categoryId}`, {
      responseKind: "json",
    });

    if (response.ok) {
      const body = await response.json;
      const selectedId = this.reportTemplateSectionSelectTarget.dataset.id;

      this.reportTemplateSectionSelectTarget.innerHTML = "";

      let blankOption = document.createElement("option");
      blankOption.value = "";
      blankOption.innerText = "Select Section";
      this.reportTemplateSectionSelectTarget.appendChild(blankOption);

      for (const section of body) {
        let option = document.createElement("option");
        option.value = section.id;
        option.innerText = section.name;

        if (selectedId && selectedId == section.id.toString()) {
          option.selected = true;
        }

        this.reportTemplateSectionSelectTarget.appendChild(option);
      }
    }
  }
}
