import { Controller } from "@hotwired/stimulus";
import { createPopper } from "@popperjs/core";

// Connects to data-controller="intake-documents--confirmable-field"
export default class extends Controller {
  static values = {
    field: String,
    value: String,
  };
  static targets = ["field", "tooltip"];
  static outlets = ["intake-documents--form"];

  connect() {
    // Create the popper instance
    this.popper = createPopper(this.fieldTarget, this.tooltipTarget, {
      placement: "right",
      modifiers: [
        {
          name: "offset",
          options: {
            offset: [0, 10],
          },
        },
      ],
    });

    this._tooltipVisible = true;
    this.popper.update();
  }

  toggleTooltip(e) {
    e.preventDefault();

    if (this._tooltipVisible) {
      this._hideTooltip();
    } else {
      this._showTooltip();
    }
  }

  fieldCorrect(e) {
    e.preventDefault();

    // Hide the tooltip
    this._hideTooltip();

    // Change the color of the field to green
    this.fieldTarget.classList.add("text-success");
  }

  fieldWrong(e) {
    e.preventDefault();

    // Hide the tooltip
    this._hideTooltip();

    // Change the color of the field to red
    this.fieldTarget.classList.add("text-danger");

    this.intakeDocumentsFormOutlet.addIncorrectField(
      this.fieldValue,
      this.valueValue
    );
  }

  _showTooltip() {
    this.tooltipTarget.setAttribute("data-show", "");
    this.popper.update();

    this._tooltipVisible = true;
  }

  _hideTooltip() {
    this.tooltipTarget.removeAttribute("data-show");

    this._tooltipVisible = false;
  }
}
