// TODO: Convert to generic controller
import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="settings--permissions--row-helper"
export default class extends Controller {
  static targets = ["permissions"];

  connect() {}

  checkAll(e) {
    e.preventDefault();

    this.permissionsTarget.querySelectorAll("input").forEach((element) => {
      if (element.type == "checkbox") {
        element.checked = true;
      }
    });
  }

  clear(e) {
    e.preventDefault();

    this.permissionsTarget.querySelectorAll("input").forEach((element) => {
      if (element.type == "checkbox") {
        element.checked = false;
      }
    });
  }
}
