import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="billing--payer-field"
export default class extends Controller {
  static targets = ['inputField']

  fillPayer(e) {
    e.preventDefault()

    if (e.target.checked) {
      this.inputFieldTarget.value = e.target.value
      return
    }

    this.inputFieldTarget.value = ''
  }
}
