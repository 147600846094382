import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="appointments--patient-search"
export default class extends Controller {
  static outlets = ["appointments--form"];

  connect() {
    this._isPatientSelected = this.patientIdValue !== 0;
  }

  selectPatient(e) {
    let patientId = e.target.dataset.patientId;

    if (e.target.checked) {
      this._isPatientSelected = true;

      this.appointmentsFormOutlets.forEach((outlet) => {
        outlet.show(patientId);
      });

      e.target.parentElement.requestSubmit();
    } else {
      this._isPatientSelected = false;

      this.appointmentsFormOutlets.forEach((outlet) => {
        outlet.hide();
      });
    }
  }

  changePatient(e) {
    // e.preventDefault();

    // Hide the appointment form
    this.appointmentsFormOutlets.forEach((outlet) => {
      outlet.hide();
    });
  }
}
