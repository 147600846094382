import { Controller } from "@hotwired/stimulus";
import { get } from "@rails/request.js";

export default class extends Controller {
  static targets = ["select"];
  static values = {
    url: String,
    //param: String
  };

  connect() {
    if (this.selectTarget.id === "") {
      this.selectTarget.id = Math.random().toString(36);
    }
  }

  change(event) {
    if (!event.target.value) return;

    let url = this.urlValue;

    let urlAttribute = event.params.urlAttribute;
    if (urlAttribute) {
      // Replace the urlAttribute with the value
      url = url.replace(urlAttribute, event.target.value);
    }

    let params = new URLSearchParams();
    // params.append(this.paramValue, event.target.value)
    params.append("target", this.selectTarget.id);

    get(`${url}?${params}`, {
      responseKind: "turbo-stream",
    });
  }
}
