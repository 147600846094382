import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    categories: Object
  }
  static targets = ["subCategorySelect"]

  categorySelected(event) {
    let value = event.target.value

    if (value) {
      let subCategories = this.categoriesValue[value]

      // If subCategories exists, populate the subCategorySelect
      if (subCategories.length > 0) {
        this.subCategorySelectTarget.innerHTML = ""

        // Create the prompt option
        let promptOption = document.createElement("option")
        promptOption.value = ""
        promptOption.innerText = "Select Subcategory"
        this.subCategorySelectTarget.appendChild(promptOption)

        subCategories.forEach(subCategory => {
          let option = document.createElement("option")
          option.value = subCategory
          option.innerText = subCategory
          this.subCategorySelectTarget.appendChild(option)
        })

        // Show the field
        this.subCategorySelectTarget.classList.remove("d-none")
      } else {
        this.clearSubCategorySelect()
      }
    } else {
      this.clearSubCategorySelect()
    }
  }

  clearSubCategorySelect() {
    this.subCategorySelectTarget.innerHTML = ""
    this.subCategorySelectTarget.classList.add("d-none")
  }
}