import { Controller } from "@hotwired/stimulus";
import SignaturePad from "signature_pad";
import Swal from "sweetalert2";
import * as bootstrap from "bootstrap";

// Connects to data-controller="signature-pad"
export default class extends Controller {
  static values = {
    backdrop: { type: String, default: "true" },
  };

  static targets = ["modal", "canvas"];
  static outlets = ["signature-pad-receiver"];

  connect() {
    this.modal = new bootstrap.Modal(this.modalTarget, {
      backdrop: this.backdropValue,
    });
  }

  show(event) {
    event.preventDefault();

    this.modal.show();
    this._createSignaturePad();
  }

  sign(event) {
    event.preventDefault();

    // Check to see if a signature is present
    if (!this._signaturePad.isEmpty()) {
      this.signaturePadReceiverOutlet.addSignature(
        this._signaturePad.toDataURL("image/svg+xml")
      );
      this.modal.hide();
    } else {
      Swal.fire({
        icon: "error",
        text: "A signature is required",
      });
    }
  }

  resizeSignaturePad() {
    if (this._signaturePad) {
      this._signaturePad.clear();

      let ratio = Math.max(window.devicePixelRatio || 1, 1);

      this.canvasTarget.width = this.canvasTarget.offsetWidth * ratio;
      this.canvasTarget.height = this.canvasTarget.offsetHeight * ratio;

      this.canvasTarget.getContext("2d").scale(ratio, ratio);
    }
  }

  _createSignaturePad() {
    this._signaturePad = new SignaturePad(this.canvasTarget, {
      backgroundColor: "rgb(0, 0, 0, 0)",
    });

    this.resizeSignaturePad();
  }
}
