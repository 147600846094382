// Entry point for the build script in your package.json
import "@hotwired/turbo-rails";
import * as ActiveStorage from "@rails/activestorage";
import "./controllers";
import "../components";

import { application } from "./controllers/application";

import * as bootstrap from "bootstrap";

// Register flatpickr controller
import Flatpickr from "stimulus-flatpickr";
application.register("flatpickr", Flatpickr);

String.prototype.parameterize = function () {
  return this.trim()
    .toLowerCase()
    .replace(/[^a-zA-Z0-9 -]/, "")
    .replace(/\s/g, "-");
};

String.prototype.toSentenceCase = function () {
  return (
    this
      // Split the string by underscores
      .split("_")
      // Convert each element to lowercase
      .map((word) => word.toLowerCase())
      // Join the elements with a space
      .join(" ")
  );
};

// Register custom web components
document.addEventListener("turbo:load", () => {
  // Setup bootstrap tooltips
  const tooltipTriggerList = document.querySelectorAll(
    '[data-bs-toggle="tooltip"]',
  );
  [...tooltipTriggerList].map(
    (tooltipTriggerEl) => new bootstrap.Tooltip(tooltipTriggerEl),
  );
});
