import { Controller } from "@hotwired/stimulus";
import { FetchRequest } from "@rails/request.js";

// Connects to data-controller="report-templates--dynamic-content"
export default class extends Controller {
  static values = { url: String };
  static targets = ["valueContainer"];

  connect() { }

  async selectedFieldChanged(event) {
    let selectedField = event.target.value;
    if (selectedField) {
      let params = new URLSearchParams();
      params.append("field", selectedField);

      const request = new FetchRequest(
        "GET",
        `${this.urlValue}/conditions?${params}`,
        {
          responseKind: "turbo-stream",
        },
      );

      const response = await request.perform();
      if (response.ok) {
        this.element.dataset.selectedField = selectedField;
      }
    }
  }

  async conditionChanged(event) {
    let condition = event.target.value;
    if (condition && (condition == "EQUALS" || condition == "DOES_NOT_EQUAL")) {
      let params = new URLSearchParams();
      params.append("field", this.element.dataset.selectedField);

      const request = new FetchRequest(
        "GET",
        `${this.urlValue}/values?${params}`,
        {
          responseKind: "turbo-stream",
        },
      );
      const response = await request.perform();

      if (response.ok) {
        this.valueContainerTarget.classList.remove("d-none");
      }
    } else {
      this.valueContainerTarget.classList.add("d-none");
    }
  }

  addContentClicked(event) {
    event.preventDefault();

    // Check if all required fields exist
    let field = this.element.querySelector("#selected-field").value;
    let condition = this.element.querySelector("#condition").value;
    let content = tinymce.get("content").getContent();
    let value = this.element.querySelector("#value").value;

    if (!field) {
      alert("Please select a field");
      return;
    }

    if (!condition) {
      alert("Please select a condition");
      return;
    }

    if (!content) {
      alert("Please enter content");
      return;
    }

    if (!this.valueContainerTarget.classList.contains("d-none") && !value) {
      alert("Please enter a value");
      return;
    }

    // Send a message back to the main TinyMCE instance
    window.parent.postMessage({
      mceAction: "insertDynamicContent",
      data: {},
    });
  }
}
