import { Controller } from "@hotwired/stimulus";
import { get } from "@rails/request.js";

// Connects to data-controller="practitioner-info"
export default class extends Controller {
  static values = { url: String };
  static targets = ["turbo"];

  connect() { }

  update(e) {
    let id = e.target.value;
    if (!id) {
      this.turboTarget.innerHTML = "";
      return;
    }

    let url = this.urlValue.replace(":id", id);

    get(url, {
      responseKind: "turbo-stream",
    });
  }
}
