import FieldDialog from "../ui/field-dialog"

const setupFieldTagComponent = (win, doc, editor, fields) => {
  const template = doc.createElement('template')

  template.innerHTML = `
    <style>
      :host {
        
      }

      .intake-document-field-affix {
        background-color: rgba(0,108,231,.1);
        color: #006ce7;
      }

      :host(:hover) {
        background-color: rgba(0,108,231,.1);
      }

      :host([contentEditable=false][data-mce-selected]) {
        cursor: not-allowed;
        outline: 3px solid #b4d7ff;
      }
    </style>
    
    <span>
      <slot>field-not-defined</slot>
    </span>
  `

  class IntakeDocumentField extends win.HTMLElement {
    constructor() {
      super()

      this.setAttribute('contenteditable', false)
      const shadow = this.attachShadow({ mode: 'open' })

      let prefixElement = document.createElement('span')
      prefixElement.classList.add('intake-document-field-affix')
      prefixElement.innerHTML = '{*'

      this.shadowRoot.appendChild(prefixElement)

      this.shadowRoot.appendChild(template.content.cloneNode(true))

      let affixElement = document.createElement('span')
      affixElement.classList.add('intake-document-field-affix')
      affixElement.innerHTML = '*}'

      this.shadowRoot.appendChild(affixElement)
    }

    connectedCallback() {
      const editField = () => {
        fields.then((result) => {
          let combinedFields = this.combinedFields(result)
          let field = combinedFields.find(f => f.id == this.dataset.fieldId)
          
          const fieldDialog = new FieldDialog(editor, field, this)
          fieldDialog.show()
        })
      }

      this.shadowRoot.addEventListener('dblclick', editField)
    }

    combinedFields(fields) {
      let result = []
      fields.forEach(f => {
        result.push(f)
        if (Array.isArray(f.subitems)) {
          result = result.concat(this.combinedFields(f.subitems))
        }
      })

      return result
    }
  }

  win.customElements.define('intake-document-field', IntakeDocumentField)
}

export { setupFieldTagComponent }
