import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="slider-value"
export default class extends Controller {
  static targets = ["output"]

  connect() {
    // Find the intial value of the slider and set the output to that value
    this.outputTarget.innerHTML = this.element.querySelector('input').value 
  }

  updateOutput(event) {
    this.outputTarget.innerHTML = event.target.value
  }
}
