import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["subtotal", "quantity", "total"]

  updateTotal() {
    const subtotal = parseFloat(this.subtotalTarget.value)
    const quantity = parseFloat(this.quantityTarget.value)
    const total = subtotal * quantity
    this.totalTarget.value = total.toFixed(2)
  }
}