import WysiwygController from "../wysiwyg_controller";

// Custom Plugins
import "../../tinymce/plugins/intake-document-template-fields";

export default class extends WysiwygController {
  static values = {
    fieldsUrl: String,
    documentType: String,
  };

  get optionsValue() {
    return {
      plugins: "lists advlist table code intake-document-template-fields",
      toolbar2: "intake-document-fields",
      custom_elements: "~intake-document-field",
      content_css: "document",
      intakeDocumentFieldOptions: {
        fieldsUrl: this.fieldsUrlValue,
        documentType: this.documentTypeValue,
      },
    };
  }
}
