import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["source", "form", "input"]

  static values = {
    model: String,
    name: String,
    inputClass: String,
    dropdownOptions: Array,
    url: String
  }

  toggle(event) {
    event.preventDefault()

    if (this.data.get("toggled") != 1) {
      this.data.set('toggled', 1)
      this.data.set('previousValue', this.sourceTarget.innerHTML)

      this.sourceTarget.innerHTML = this.form()

      event.target.innerHTML = 'Cancel'
    } else {
      this.sourceTarget.innerHTML = this.data.get('previousValue')
      this.data.set('toggled', 0)

      event.target.innerHTML = 'Edit'
    }
  }

  form() {
    return `
      <form action="${this.urlValue}" accept-charset="UTF-8" data-inline-select-edit-target="form" method="post">
        <input name="utf8" type="hidden" value="✓">
        <input type="hidden" name="_method" value="patch">
        <input type="hidden" name="authenticity_token" value="${this.authToken}">

        <div class="form-group select">
          <select class="${this.inputClassValue}" name="${this.modelValue}[${this.nameValue}]" id="${this.modelValue}_${this.nameValue}" data-inline-select-edit-target="input" data-action="change->inline-select-edit#submit">
            ${this.dropdownOptionsValue.map(item => (`<option value="${item['value']}" ${(this.data.get('previousValue') == item["text"]) ? 'selected' : ''}>${item['text']}</option>`)).join('')}
          </select>
      </form>
    `
  }

  submit() {
    this.formTarget.submit()
  }

  get authToken() {
    return document.querySelector("meta[name='csrf-token']").getAttribute("content")
  }
}