import { FetchRequest } from "@rails/request.js";

export const makeRequest = async (url) => {
  const request = new FetchRequest("get", url, {
    responseKind: "json",
    contentType: "application/json",
  });

  const response = await request.perform();
  const json = await response.json;

  return json;
};
