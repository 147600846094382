import { Controller } from "@hotwired/stimulus";
import * as bootstrap from "bootstrap";

// Connects to data-controller="modal"
export default class extends Controller {
  static values = {
    backdrop: { type: String, default: "true" },
  };

  connect() {
    // Clear any open backdrops
    document.querySelectorAll(".modal-backdrop").forEach((backdrop) => {
      backdrop.remove();
    });

    this.modal = new bootstrap.Modal(this.element, {
      backdrop: this.backdropValue,
    });

    this.element.addEventListener("hidden.bs.modal", (event) => {
      console.log("hidden.bs.modal event fired");
      this.close();
    });

    // Show the modal
    this.modal.show();
    this._visible = true;

    // Add event for turbo form submit to automatically close the modal
    document.addEventListener("turbo:submit-end", (event) => {
      if (
        !("noModalDismiss" in event.target.dataset) &&
        event.detail.success &&
        this._visible
      ) {
        this.modal.hide();
        this.close();
      }
    });
  }

  close() {
    console.log("close called");
    this._visible = false;
    this.element.remove();
    this.modalTurboFrame.src = null;
  }

  get modalTurboFrame() {
    return document.querySelector("turbo-frame[id='modal']");
  }
}
