import { Controller } from "@hotwired/stimulus";

const styles = `
  body {
    font-family: 'Times New Roman';
    margin: 1rem auto;
  }

  .mceNonEditable {
    background-color: #D6F0FF;
    padding: 1px 0;
    color: #44719B;
    font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
    font-size: 0.9375em;
  }
`;

export default class extends Controller {
  static targets = ["field"];
  static values = {
    height: { type: String, default: "80vh" },
  };

  connect() {
    let config = Object.assign(
      { target: this.fieldTarget },
      this.editorOptions,
    );
    tinymce.init(config);
  }

  disconnect() {
    tinymce.remove();
  }

  get defaultOptions() {
    return {
      base_url: "/assets/tinymce",
      content_style: styles,
      plugins: "lists advlist table code",
      branding: false,
      height: this.heightValue,
      fontsize_formats: "10px 12px 14px 16px 18px 24px 26px 36px",
      menubar: "edit view insert format tools table",
      toolbar1:
        "undo redo | blocks fontsize | bold italic underline forecolor backcolor | link | alignleft aligncenter alignright alignjustify lineheight | checklist bullist numlist indent outdent | removeformat | pagebreak",
      contextmenu_never_use_native: false,
      content_css: "document",
      promotion: false,
    };
  }

  get editorOptions() {
    return {
      ...this.defaultOptions,
      ...this.optionsValue,
    };
  }
}
