export default class FieldDialog {
  // Variables
  editor;
  field;
  dialog;
  isPredefinedField;

  constructor(editor, field, initialData = null) {
    this.editor = editor;
    this.field = field;
    this.isPredefinedField = field.predefined_field || false;

    this.initialData = initialData;
  }

  show() {
    this.dialog = this.editor.windowManager.open(this.#getDialogConfig());
  }

  #getDialogConfig() {
    let panelItems = this.isPredefinedField
      ? this.#predefinedFieldSettings()
      : this.#sectionFieldSettings();

    const initialData = {};
    if (this.initialData) {
      if (this.initialData["data-field-variant"]) {
        initialData.variant = this.initialData["data-field-variant"].value;
      }

      if (this.initialData["data-field-case-type"]) {
        initialData.caseType = this.initialData["data-field-case-type"].value;
      }

      if (this.initialData["data-field-list-type"]) {
        initialData.listType = this.initialData["data-field-list-type"].value;
      }

      if (this.initialData["data-field-default-text"]) {
        initialData.defaultText =
          this.initialData["data-field-default-text"].value;
      }
    }

    return {
      title: this.field.name,
      body: {
        type: "panel",
        items: panelItems,
      },
      initialData: initialData,
      buttons: [
        {
          type: "cancel",
          name: "closebutton",
          text: "Cancel",
        },
        {
          type: "submit",
          name: "submitbutton",
          text: "OK",
          primary: true,
        },
      ],
      onSubmit: (api) => {
        let data = api.getData();
        let filters = [];

        // Add Variant Filter
        if (data.variant) {
          filters.push({ filter: "variant", value: data.variant });
        }

        // Add case type filter
        if (data.caseType) {
          filters.push({ filter: data.caseType });
        }

        // Add list type
        if (data.listType) {
          filters.push({ filter: "list_type", value: data.listType });
        }

        // Add Default Text
        if (data.defaultText) {
          filters.push({ filter: "default_text", value: data.defaultText });
        }

        let liquid = "{{ ";

        if (this.isPredefinedField) {
          liquid += `predefined_fields.${this.field.key}`;
        } else {
          liquid += this.field.key;
        }

        for (const filter of filters) {
          if (filter.value) {
            liquid += ` | ${filter.filter}: '${filter.value}'`;
          } else {
            liquid += ` | ${filter.filter}`;
          }
        }

        liquid += " }}";

        // Insert the liquid tag
        this.editor.insertContent(liquid);

        // Close the dialog
        api.close();
      },
    };
  }

  #predefinedFieldSettings() {
    let panelItems = [];

    if (this.field.variants.length > 0) {
      panelItems.push({
        type: "selectbox",
        name: "variant",
        items: this.field.variants.map((variant) => {
          return {
            value: variant,
            text: variant.replaceAll("_", " "),
          };
        }),
      });
    }

    if (this.field.enable_letter_case) {
      panelItems.push({
        type: "selectbox",
        name: "caseType",
        label: "Letter Case",
        items: [
          { value: "", text: "None" },
          {
            value: "capitalize",
            text: "Capitalize First Letter",
          },
          { value: "titleize", text: "Capitalize First Letter of each Word" },
          { value: "upcase", text: "Upper Case" },
          { value: "downcase", text: "Lower Case" },
        ],
      });
    }

    return panelItems;
  }

  #sectionFieldSettings() {
    let panelItems = [
      {
        type: "selectbox",
        name: "caseType",
        label: "Letter Case",
        items: [
          { value: "", text: "None" },
          {
            value: "capitalize",
            text: "Capitalize First Letter",
          },
          { value: "titleize", text: "Capitalize First Letter of each Word" },
          { value: "upcase", text: "Upper Case" },
          { value: "downcase", text: "Lower Case" },
        ],
      },
      {
        type: "input",
        name: "defaultText",
        label: "If we find this field empty, what should we put in its place?",
      },
    ];

    // If the field has responses and is a checkbox, add the list type
    if (
      this.field.values &&
      this.field.values.length > 0 &&
      this.field.field_type == "Checkbox"
    ) {
      panelItems.push({
        type: "selectbox",
        name: "listType",
        label: "Would you like to display the field as a list?",
        items: [
          { value: "comma", text: "Comma Separated List" },
          { value: "unordered", text: "Bulleted List" },
          { value: "ordered", text: "Numbered List" },
        ],
      });
    }

    return panelItems;
  }
}
