import { Controller } from "@hotwired/stimulus"
import { get } from "@rails/request.js"

export default class extends Controller {
  static targets = ['reportTemplateSelect', 'sectionSelect', 'fieldSelect']
  static values = {
    sectionsUrl: String,
    fieldsUrl: String
  }

  connect() {
    if (this.reportTemplateSelectTarget.value)
      this.loadSections()
  }

  async loadSections() {
    let value = this.reportTemplateSelectTarget.value

    if (value) {
      let url = this.sectionsUrlValue.replace(':report_template_id', value)

      let response = await get(url, {
        responseKind: 'json'
      })

      if (response.ok) {
        let data = await response.json
        let blankOption = document.createElement('option')

        this.sectionSelectTarget.innerHTML = ''
        this.sectionSelectTarget.appendChild(blankOption)

        data.forEach(section => {
          let optGroup = document.createElement('optgroup')
          optGroup.label = section.name

          section.children.forEach(child => {
            let option = document.createElement('option')
            option.value = child.id
            option.innerText = child.name

            optGroup.appendChild(option)
          })

          this.sectionSelectTarget.appendChild(optGroup)
        })

        let selectedValue = this.sectionSelectTarget.dataset.selectedValue
        if (selectedValue) {
          this.sectionSelectTarget.value = selectedValue
          this.loadFields()
        }
      }
    }
  }

  async loadFields() {
    let reportTemplateId = this.reportTemplateSelectTarget.value
    let reportTemplateSectionId = this.sectionSelectTarget.value

    if (reportTemplateId && reportTemplateSectionId) {
      let url = this.fieldsUrlValue.replace(':report_template_section_id', reportTemplateSectionId).replace(':report_template_id', reportTemplateId)

      let response = await get(url, {
        responseKind: 'json'
      })

      if (response.ok) {
        let data = await response.json
        let blankOption = document.createElement('option')

        this.fieldSelectTarget.innerHTML = ''
        this.fieldSelectTarget.appendChild(blankOption)

        data.forEach(field => {
          let option = document.createElement('option')
          option.value = field.id
          option.innerText = field.name
          this.fieldSelectTarget.appendChild(option)
        })

        let selectedValue = this.fieldSelectTarget.dataset.selectedValue
        if (selectedValue) {
          this.fieldSelectTarget.value = selectedValue
        }
      }
    }
  }
}