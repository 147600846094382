import Flatpickr from "stimulus-flatpickr";

// Connects to data-controller="appointments--calendar--date-picker"
export default class extends Flatpickr {
  change(selectedDates, dateStr, instance) {
    const date = selectedDates[0];
    const url = new URL(window.location);
    url.searchParams.set("start_date", date.toISOString().split("T")[0]);

    window.location.href = url;
  }
}
