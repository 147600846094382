// From: https://github.com/stimulus-components/stimulus-rails-nested-form/blob/master/src/index.ts
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['target', 'template']
  static values = {
    wrapperSelector: { type: String, default: '.nested-form-wrapper' },
  }

  add(event) {
    event.preventDefault()

    const content = this.templateTarget.innerHTML.replace(/NEW_RECORD/g, new Date().getTime().toString())
    this.targetTarget.insertAdjacentHTML('beforeend', content)
  }

  remove(event) {
    event.preventDefault()

    const wrapper = event.target.closest(this.wrapperSelectorValue)

    if (wrapper.dataset.newRecord === 'true') {
      wrapper.remove()
    } else {
      wrapper.style.display = 'none'

      const input = wrapper.querySelector('input[name*="_destroy"]')
      input.value = '1'
    }
  }
}