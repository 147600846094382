import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["sliderInputs", "responsesContainer"];

  connect() {}

  fieldTypeChanged(e) {
    if (e.target.value === "Slider") {
      this.sliderInputsTarget.classList.remove("d-none");
    } else {
      this.sliderInputsTarget.classList.add("d-none");
    }

    if (
      e.target.value === "Radio" ||
      e.target.value === "Checkbox" ||
      e.target.value === "Medication"
    ) {
      this.responsesContainerTarget.classList.remove("d-none");
    } else {
      this.responsesContainerTarget.classList.add("d-none");
    }
  }
}
