import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['container', 'template']

  addInput(event) {
    event.preventDefault()

    const content = this.templateTarget.innerHTML
    this.containerTarget.insertAdjacentHTML('beforeend', content)
  }

  removeInput(event) {
    event.preventDefault()

    let arrayFieldToRemove = event.target.closest('.array-input')
    arrayFieldToRemove.remove()
  }
}