import { makeRequest } from "../utils";
import FieldDialog from "./field_dialog";
import DynamicContentDialog from "./dynamic_content_dialog";

export const register = (editor, options) => {
  editor.ui.registry.addMenuButton("report-template-fields", {
    text: "Insert Field",
    icon: "template",
    fetch: async (callback) => {
      let menuItems = [];

      // Add predefined fields
      const predefinedFields = await makeRequest(
        options["predefinedFieldsUrl"],
      );

      menuItems.push({
        type: "menuitem",
        text: "Precreated Fields",
        getSubmenuItems: () => {
          return predefinedFields.map((field) => {
            return {
              type: "menuitem",
              text: field.name,
              onAction: () => {
                // If field has variants or enable_letter_case is set to true, prompt the user for the options
                // If it doesn't, insert the field into the editor
                if (field.variants.length > 0 || field.enable_letter_case) {
                  let dialog = new FieldDialog(editor, field);
                  dialog.show();
                } else {
                  let liquid = `{{ predefined_fields.${field.key} }}`;
                  editor.insertContent(liquid);
                }
              },
            };
          });
        },
      });

      if (options["widgetType"] === "procedure") {
        menuItems.push({
          type: "menuitem",
          text: "Procedure Fields",
          getSubmenuItems: () => {
            return [
              {
                type: "menuitem",
                text: "Procedure Location",
                onAction: () => {
                  let field = {
                    id: "procedure_location",
                    name: "Procedure Location",
                    variants: [],
                    enable_letter_case: true,
                    predefined_field: true,
                  };

                  let dialog = new FieldDialog(editor, field);
                  dialog.show();
                },
              },
              {
                type: "menuitem",
                text: "Procedure Type",
                onAction: () => {
                  let field = {
                    id: "procedure_type",
                    name: "Procedure Type",
                    variants: [],
                    enable_letter_case: true,
                    predefined_field: true,
                  };

                  let dialog = new FieldDialog(editor, field);
                  dialog.show();
                },
              },
            ];
          },
        });
      }

      if (options["fieldsUrl"]) {
        const sectionFields = await makeRequest(options["fieldsUrl"]);

        if (sectionFields.length > 0) {
          menuItems.push({
            type: "menuitem",
            text: "Section Fields",
            getSubmenuItems: () => {
              return sectionFields.map((field) => {
                return {
                  type: "menuitem",
                  text: field.name,
                  onAction: () => {
                    let dialog = new FieldDialog(editor, field);
                    dialog.show();
                  },
                };
              });
            },
          });
        }
      }

      callback(menuItems);
    },
  });

  editor.ui.registry.addButton("report-template-dynamic-content", {
    text: "Insert Dynamic Content",
    icon: "template",
    onAction: async () => {
      const fields = await makeRequest(options["fieldsUrl"]);
      const dialog = new DynamicContentDialog(editor, fields);
      dialog.show();
    },
  });
};
