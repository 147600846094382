import RevealController from "./reveal_controller";

// Connects to data-controller="reveal-on-input"
export default class extends RevealController {
  connect() {
    super.connect();

    console.log("connected");
  }

  toggle(event) {
    if (event.target.value === "true") {
      super.show();
    } else {
      super.hide();
    }
  }
}
