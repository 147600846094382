const setupReportTemplateDynamicContentTag = (win, doc, editor) => {
  const template = doc.createElement("template");
  template.innerHTML = `
    <style>
      :host {}
      :host(:hover) {
        background-color: rgba(0, 108, 231, 0.1);
      }
      :host([contentEditable="false"][data-mce-selected]) {
        cursor: not-allowed;
        outline: 3px solid #b4d7ff;
      }
      
      .report-template-field-affix {
        background-color: rgba(0, 108, 231, 0.1);
        color: #006ce7;
      }
    </style>

    <span>
      <span class="report-template-field-affix">{* if <span id="condition"></span> *}</span>
      <span class="content">
        <slot></slot>
      </span>
      <span class="report-template-field-affix">{* end *}</span>
    </span>
  `;

  class ReportTemplateDynamicContentTag extends win.HTMLElement {
    constructor() {
      super();

      this.attachShadow({ mode: "open" });
      this.shadowRoot.appendChild(template.content.cloneNode(true));
    }

    connectedCallback() {
      this.setAttribute("contentEditable", false);

      const cleanupContentEditable = () => {
        if (this.firstChild.contentEditable !== "true") {
          const editableWrapper = document.createElement("span");
          editableWrapper.setAttribute("contentEditable", true);

          while (this.firstChild) {
            editableWrapper.appendChild(this.firstChild);
          }

          this.appendChild(editableWrapper);
        } else {
          if (!editor) {
            this.firstChild.setAttribute("contentEditable", false);
          }
        }
      };

      cleanupContentEditable();

      if (editor) {
        this.addEventListener("dblclick", (e) => {
          if (e.target == e.currentTarget) {
            const event = new CustomEvent("dynamic-content-dialog:open", {
              detail: {
                fieldId: this.getAttribute("data-id"),
                dynamicContentNode: this,
              },
            });

            editor.getDoc().dispatchEvent(event);
          }
        });
      }

      // Prevent the element from being completely deleted
      this.addEventListener("input", () => {
        const content = this.shadowRoot.querySelector("span");
        console.log(content);

        if (content && content.textContent.trim() === "") {
          // Insert a non-breaking space or other placeholder
          content.innerHTML = "&#8203;"; // Zero-width space
        }
      });
    }

    attributeChangedCallback(name, oldValue, newValue) {
      if (name === "data-condition-text") {
        this.shadowRoot.querySelector("#condition").textContent = newValue;
      }
    }

    static get observedAttributes() {
      return [
        "data-field-id",
        "data-condition",
        "data-value",
        "data-condition-text",
      ];
    }
  }

  win.customElements.define(
    "report-template-dynamic-content",
    ReportTemplateDynamicContentTag,
  );
};

export { setupReportTemplateDynamicContentTag };
