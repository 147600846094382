import { Controller } from "@hotwired/stimulus"
import Swal from "sweetalert2"
import { put } from "@rails/request.js"

// Connects to data-controller="reports--copy-section"
export default class extends Controller {
  async copy(event) {
    event.preventDefault()

    const sectionUrl = event.params.sectionUrl

    Swal.fire({
      title: "Warning",
      text: "Copying this section will overwrite any values you have entered. Are you sure you want to continue?",
      showDenyButton: true,
      showCancelButton: true,
      confirmButtonText: "Yes",
      denyButtonText: "No",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const response = await put(`${sectionUrl}.json`, { contentType: 'application/json', responseKind: 'json' })
        if (response.ok) {
          Swal.fire({
            title: "Success",
            text: "Section copied successfully",
          })
        }
      }
    })
  }
}
