import WysiwygController from "../wysiwyg_controller";

import "../../tinymce/plugins/report-template-fields";

// Connects to data-controller="report-templates--widgets"
export default class extends WysiwygController {
  static values = {
    predefinedFieldsUrl: String,
    fieldsUrl: String,
    fieldUrl: String,
    dynamicContentUrl: String,
    widgetType: { type: String, default: "questionnaire" },
  };

  get optionsValue() {
    let toolbarButtons = "report-template-fields";

    // if (this.widgetTypeValue === "questionnaire") {
    //   toolbarButtons += " report-template-dynamic-content";
    // }

    return {
      noneditable_class: "mceNonEditable",
      plugins: "lists advlist table code report-template-fields",
      toolbar2: toolbarButtons,
      noneditable_regexp: /\{\{[^\}]+\}\}/g, // matches {{handlebar}},
      setup: (editor) => {
        editor.on("dblclick", (event) => {
          if (
            event.target.nodeName == "SPAN" &&
            event.target.classList.contains("mceNonEditable")
          ) {
          }
        });
      },
      reportTemplateFieldsOptions: {
        predefinedFieldsUrl: this.predefinedFieldsUrlValue,
        fieldsUrl: this.fieldsUrlValue,
        fieldUrl: this.fieldUrlValue,
        dynamicContentUrl: this.dynamicContentUrlValue,
        widgetType: this.widgetTypeValue,
      },
    };
  }
}
