import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="appointments--form"
export default class extends Controller {
  static targets = ["form", "patientInputField"];

  connect() {
    this._visible = false;
  }

  toggleForm(patientId) {
    if (this._visible) {
      this.hide();
    } else {
      this.show(patientId);
    }
  }

  show(patientId = null) {
    this.formTarget.classList.remove("d-none");
    this.formTarget.classList.add("d-block");

    if (patientId) {
      // Set the value of the patientInputField
      this.patientInputFieldTarget.value = patientId;

      // Trigger the change event
      this.patientInputFieldTarget.dispatchEvent(new Event("change"));
    }

    this._visible = true;
  }

  hide() {
    this.formTarget.classList.add("d-none");
    this.formTarget.classList.remove("d-block");

    // Remove the patient id from the input
    this.patientInputFieldTarget.value = "";

    this._visible = false;
  }
}
