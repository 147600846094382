import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="intake-documents--pain-locations"
export default class extends Controller {
  static targets = ["field", "painLevel"];

  activeClasses = ["bg-body-secondary"];

  painLevelClicked(event) {
    event.preventDefault();

    let painLevel = event.params.level;
    this.fieldTarget.value = painLevel;

    this.painLevelTargets.forEach((painLevelContainer, index) => {
      painLevelContainer.classList.remove(...this.activeClasses);

      if (index == painLevel) {
        painLevelContainer.classList.add(...this.activeClasses);
      }
    });
  }
}
