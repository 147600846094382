import FieldDialog from './field-dialog'

const register = (editor, fields) => {
  editor.ui.registry.addMenuButton('intake-document-fields', {
    text: 'Insert Field',
    fetch: async function(callback) {
      fields.then((result) => {
        let menuItems = result.map(field => {
          return {
            type: 'menuitem',
            text: field.display_name,
            getSubmenuItems: () => getChildItems(editor, field),
            onAction: () => showFieldDialog(editor, field)
          }
        })

        callback(menuItems)
      })
    }
  })
}

function getChildItems(editor, field) {
  const subItems = []

  if (field.subitems && field.subitems.length > 0) {
    field.subitems.forEach(subItem => {
      subItems.push({
        type: 'menuitem',
        text: subItem.display_name,
        getSubmenuItems: () => getChildItems(editor, subItem),
        onAction: () => showFieldDialog(editor, subItem)
      })
    })
  }

  return subItems
}

function showFieldDialog(editor, field) {
  if (field.subitems && field.subitems.length > 0) {
    return
  }

  const fieldDialog = new FieldDialog(editor, field)
  fieldDialog.show()
}

export { register }