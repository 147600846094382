export default class FieldDialog {
  #fieldToInsert
  #editor
  #dialog
  #intakeDocumentField
  data = {}

  constructor(editor, field, intakeDocumentField) {
    this.#fieldToInsert = field
    this.#editor = editor

    if (intakeDocumentField) {
      this.data = {
        fieldName: intakeDocumentField.dataset.label,
        confirmable: intakeDocumentField.dataset.confirmable === 'true'
      }

      this.#intakeDocumentField = intakeDocumentField
    }
  }

  show() {
    let items = []
    
    if (this.#fieldToInsert.options.requires_field_name) {
      items.push({
        type: 'input',
        name: 'fieldName',
        label: 'Field Name'
      })
    }

    if (this.#fieldToInsert.options.confirmable) {
      items.push({
        type: 'checkbox',
        name: 'confirmable',
        label: 'Confirmable'
      })
    }

    if (items.length > 0) {
      let options = {
        title: 'Insert Field',
        body: {
          type: 'panel',
          items: items
        },
        buttons: [
          {
            type: 'cancel',
            name: 'closebutton',
            text: 'Cancel'
          },
          {
            type: 'submit',
            name: 'submitbutton',
            text: 'OK',
            primary: true
          }
        ],
        initialData: this.data,
        onSubmit: (api) => {
          let data = api.getData()
          let fieldName = data.fieldName ? data.fieldName : this.#fieldToInsert.display_name
          this.#insertField(fieldName, data.confirmable)

          api.close()
        }
      }

      this.#dialog = this.#editor.windowManager.open(options)
      return
    }

    this.#insertField(this.#fieldToInsert.display_name)
  }

  #insertField(fieldName = '', confirmable = false) {
    let label = fieldName ? fieldName : this.#fieldToInsert.display_name
    let inputName = fieldName.trim().toLowerCase().replace(/[^a-zA-Z0-9 -]/, "").replace(/\s/g, "_")

    this.#editor.undoManager.transact(() => {
      let isEdit = true
      
      if (!this.#intakeDocumentField) {
        this.#intakeDocumentField = document.createElement('intake-document-field')
        isEdit = false
      }

      this.#intakeDocumentField.dataset.fieldId = this.#fieldToInsert.id
      this.#intakeDocumentField.dataset.fieldType = this.#fieldToInsert.name
      this.#intakeDocumentField.dataset.confirmable = confirmable
      this.#intakeDocumentField.dataset.label = label
      this.#intakeDocumentField.dataset.inputName = inputName
      this.#intakeDocumentField.innerHTML = label

      if (!isEdit) {
        this.#editor.insertContent(this.#intakeDocumentField.outerHTML)
      } else {
        this.#editor.nodeChanged()
      }
    })
  }
}