import tinymce from 'tinymce'

import * as Buttons from './ui/buttons'
import { FetchRequest } from '@rails/request.js'
import { setupFieldTagComponent } from './core/field-tag-component'

const setup = (editor) => {
  let fieldsUrl = editor.getParam('intakeDocumentFieldOptions')['fieldsUrl']
  let documentType = editor.getParam('intakeDocumentFieldOptions')['documentType']
  let fields = getFields(fieldsUrl, documentType)

  // FilterContent.register(editor)
  Buttons.register(editor, fields)

  editor.on('Init', () => {
    const win = editor.getWin()
    const doc = editor.getDoc()

    setupFieldTagComponent(win, doc, editor, fields)
  })

  editor.on('PreInit', () => {
    editor.serializer.addNodeFilter('intake-document-field', (nodes) => {
      nodes.forEach((node) => {
        if (!!node.attr("contenteditable")) {
          node.attr("contenteditable", null)
        }
      })
    })
  })
}

const getFields = async (url, documentType) => {
  const request = new FetchRequest('get', url, {
    responseKind: 'json',
    contentType: 'application/json',
    query: {
      type: documentType
    }
  })

  const response = await request.perform()
  const json = await response.json

  return json
}

export default () => {
  tinymce.PluginManager.add('intake-document-template-fields', setup)
}