import { Controller } from "@hotwired/stimulus";
import Sortable from "sortablejs";
import { FetchRequest } from "@rails/request.js";

export default class extends Controller {
  static values = {
    url: String,
    group: String,
  };

  connect() {
    let config = {
      animation: 150,
      fallbackOnBody: true,
      swapThreshold: 0.65,
      draggable: ".draggable",
      handle: ".handle",
      onEnd: this._saveOrder.bind(this),
    };

    if (this.hasGroupValue) {
      config["group"] = this.groupValue;
    }

    this.sortable = new Sortable(this.element, config);
  }

  _saveOrder = async (event) => {
    let id = event.item.dataset.id;
    let sortedArray = this.sortable.toArray();

    const request = new FetchRequest(
      "patch",
      this.urlValue.replace(":id", id),
      {
        responseKind: "turbo-stream",
        body: { position: event.newIndex + 1, sorted_array: sortedArray },
      },
    );

    await request.perform();
  };
}
